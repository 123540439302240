
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiIntegralGet, apiIntegralSet } from '@/api/setting'
import materialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    materialSelect
  }
})
export default class ListsDetail extends Vue {
  /** S Data **/

  // 添加商城表单数据
  form: any = {
    intregral_bgimage: '',
    intregral_color: null,
    intregral_image: '' // 积分列表轮播图设置
  };

  // 表单校验
  rules = {
    intregral_image: [
      { required: true, message: '请选择轮播图', trigger: 'blur' }
    ]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit (formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return
      this.handleEdit()
    })
  }

  async handleEdit () {
    await apiIntegralSet({ ...this.form })
    this.getShopDetailFunc()
  }

  // 获取详情
  async getShopDetailFunc (): Promise<void> {
    const res: any = await apiIntegralGet()
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
  }
  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.getShopDetailFunc()
  }
  /** E Life Cycle **/
}
